import React, { Component } from "react";
import image from "../images/new/phone.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Fade from "react-reveal/Fade";
import FeelBetter from "./../images/new/feel-better-icon.svg";
import Phone from "./../images/new/phone-icon.svg";
import TalkFreely from "./../images/new/talk-freely-icon.svg";

export default function FirstCarousel() {
  const data = {
    itemOne: {
      title: "Connect Instantly",
      content: `With the click of a button, you will be able to speak with one of Freely's verified listeners or licensed therapists. `,
    },
    itemTwo: {
      title: "Talk Freely",
      content:
        "Share and be heard. All of our listeners and therapists are trained to support you in whatever you’re feeling.",
    },
    itemThree: {
      title: "Affordable Online Therapy",
      content:
        "Talk with a licenced therapist at an affordable rate. Our network of providers cover a range of specialities.",
    },
  };
  return (
    <>
      <div className="bg-white ">
        <div className=" bg-freelyCream py-10 my-8">
          <div>
            <h1
              className="pt-12 text-center text-4xl
            text-freelyBlue-dark
            xxxxxxl:text-5xl xxxxxxl:my-2
            xxxxxxxl:text-6xl "
            >
              How It Works
            </h1>
            <h2
              className="text-center text-xl pt-2 text-freelyBlue-dark
              xs:mx-12  
              sm:mx-24 
              xxxxxxl:text-2xl
              xxxxxxxl:text-3xl"
            >
              Freely is building a safe and accessible community for emotional
              support, <br className="hidden lg:inline" />
              offering both free resources and licensed services to its users.
            </h2>
          </div>
          <div className="columns container mx-auto  flex flex-col  p-6 md:grid md:grid-cols-3">
            <Fade delay={600}>
              <div
                className="text-center my-10 text-freelyBlack px-3 
              xl:px-5 
              xxl:px-10 
              xxxl:px-20 "
              >
                <img className="mx-auto my-4 h-16" src={Phone} />
                <h1
                  className="text-2xl text-freelyBlue-light font-bold
                  xxxxxxl:text-3xl
                  xxxxxxxl:text-4xl"
                >
                  {data.itemOne.title}
                </h1>
                <p
                  className="text-center pt-6 pb-8 
                  sm:text-lg
                  xxxxxxl:text-xl
                  xxxxxxxl:text-2xl"
                >
                  {data.itemOne.content}
                </p>
              </div>
            </Fade>
            <Fade delay={1200}>
              <div className="text-center my-10 px-3 xl:px-5 xxl:px-10 xxxl:px-20 ">
                <img className="mx-auto my-4 h-16" src={TalkFreely} />
                <h1
                  className="text-2xl text-freelyBlue-light font-bold
                  xxxxxxl:text-3xl
                  xxxxxxxl:text-4xl"
                >
                  {data.itemTwo.title}
                </h1>
                <p
                  className="pt-6 pb-8
                sm:text-lg
                xxxxxxl:text-xl
                xxxxxxxl:text-2xl"
                >
                  {data.itemTwo.content}
                </p>
              </div>
            </Fade>
            <Fade delay={1800}>
              <div className="text-center my-10 px-3 xl:px-5 xxl:px-10 xxxl:px-20 ">
                <img className="mx-auto my-4 h-16" src={FeelBetter} />
                <h1
                  className=" text-2xl text-freelyBlue-light font-bold
                  xxxxxxl:text-3xl
                  xxxxxxxl:text-4xl"
                >
                  {data.itemThree.title}
                </h1>
                <p
                  className="pt-6 pb-8
                sm:text-lg
                xxxxxxl:text-xl
                xxxxxxxl:text-2xl"
                >
                  {data.itemThree.content}
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
}
