/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import ScreenPhone from "../images/screen-phone.png";
import First from "../images/new/first.svg";
import Second from "../images/new/second.svg";
import Third from "../images/new/third.svg";
import AppFeatures3 from "./../images/new/app-features-2.svg";
import Fade from "react-reveal/Fade";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
const typeformId = "vit7sL1m";

export default function ProfessionalHelp() {
  const openPopup = () => {
    createPopup(typeformId).open();
  };
  return (
    <div id="therapy">
      <div
        className=" flex py-8 md:items-center lg:items-start lg:px-12  xxl:pl-20 my-8  flex-row-reverse
                xxxxxxl:mx-10
                xxxxxxxxxl:ml-24
                xxxxxxxxxl:my-12 "
      >
        <Fade>
          <div
            className="text-left text-freelyBlue-dark
                        xs:mx-12 xs:text-center 
                        sm:mx-12 sm:text-center
                        md:px-12  
                        lg:w-1/2 lg:text-left lg:px-0 
                        xxl:ml-32
                        "
          >
            <h1
              className="text-4xl tracking-wider
                            xxxxxxl:text-5xl
                            xxxxxxxl:text-6xl"
            >
              Get Professional Help
            </h1>
            <h2
              className="text-xl pt-2 lg:text-lg
                            xxxxxxl:text-2xl
                            xxxxxxxl:text-3xl"
            >
              Beyond our verified listeners, Freely has a network of licensed
              therapists. For many of our users, speaking to a licensed{" "}
              <br className="hidden lg:inline" />
              professional can be supportive. Choose a plan that fits for you.
            </h2>
            <img
              className="mx-auto professional-help__phone lg:hidden"
              src={ScreenPhone}
            />
            <div className="xs:my-8 sm:my-4 lg:pt-4">
              <div className="items flex-row">
                <div
                  className="item pt-6 xs:mt-2 lg:flex
                                    xxxxxxl:my-4"
                >
                  <img
                    src={First}
                    className="object-contain 
                                        xs:mx-auto xs:h-20 
                                        sm:mx-auto 
                                        md:flex md:self-start
                                        xl:h-24 xl:m-0
                                        xxl:h-24 xxl:m-0
                                        xxxxxxl:h-32"
                  />
                  <div
                    className="details-container pl-12 xs:pl-0 sm:pl-0 md:pl-0 lg:pl-8
                                        xxxl:ml-10 "
                  >
                    <p
                      className=" text-freelyBlue-light
                                            xs:text-2xl xs:mt-4 
                                            sm:text-2xl sm:mt-2
                                            md:text-xl md:mt-0
                                            lg:text-2xl 
                                            xl:text-xl
                                            xxl:text-xl
                                            xxxxxxl:text-3xl
                                            xxxxxxxl:text-4xl first--title"
                    />
                    <p
                      className="text-freelyBlue-dark pt-3
                                            xs:text-base 
                                            sm:text-lg
                                            md:text-lg md:px-16 
                                            lg:text-base lg:px-0
                                            xl:text-base  
                                            xxxxxxl:text-xl
                                            xxxxxxxl:text-2xl first--description"
                    />
                  </div>
                </div>
                <div
                  className="item pt-6 xs:mt-4 lg:flex
                                    xxxxxxl:my-4"
                >
                  <img
                    src={Second}
                    className="object-contain 
                                        xs:mx-auto xs:h-20 
                                        sm:mx-auto 
                                        md:flex md:self-start
                                        xl:h-24 xl:m-0
                                        xxl:h-24 xxl:m-0
                                        xxxxxxl:h-32"
                  />
                  <div
                    className=" details-container pl-12 xs:pl-0 sm:pl-0 md:pl-0 lg:pl-8
                                        xxxl:ml-10 "
                  >
                    <p
                      className=" text-freelyBlue-light
                                            xs:text-2xl xs:mt-4 
                                            sm:text-2xl sm:mt-2
                                            md:text-xl md:mt-0
                                            lg:text-2xl 
                                            xl:text-xl
                                            xxxxxxl:text-3xl
                                            xxxxxxxl:text-4xl second--title"
                    />
                    <p
                      className="text-freelyBlue-dark pt-3
                                            xs:text-base 
                                            sm:text-lg
                                            md:text-lg md:px-16 
                                            lg:text-base lg:px-0
                                            xl:text-base
                                            xxxxxxl:text-xl
                                            xxxxxxxl:text-2xl second--description"
                    />
                  </div>
                </div>
                <div
                  className="item pt-6 xs:mt-4 lg:flex
                                     xxxxxxl:my-4"
                >
                  <img
                    src={Third}
                    className="object-contain 
                                        xs:mx-auto xs:h-20 
                                        sm:mx-auto 
                                        md:flex md:self-start
                                        xl:h-24 xl:m-0
                                        xxl:h-24 xxl:m-0
                                        xxxxxxl:h-32"
                  />
                  <div
                    className="details-container pl-12 xs:pl-0 sm:pl-0 md:pl-0 lg:pl-8
                                        xxxl:ml-10 "
                  >
                    <p
                      className=" text-freelyBlue-light
                                            xs:text-2xl xs:mt-4 
                                            sm:text-2xl sm:mt-2
                                            md:text-xl md:mt-0
                                            lg:text-2xl 
                                            xl:text-xl
                                            xxxxxxl:text-3xl
                                            xxxxxxxl:text-4xl third--title"
                    />
                    <p
                      className="text-freelyBlue-dark  pt-3
                                            xs:text-base 
                                            sm:text-lg
                                            md:text-lg md:px-16 
                                            lg:text-base lg:px-0
                                            xl:text-base
                                            xxxxxxl:text-xl
                                            xxxxxxxl:text-2xl third--description"
                    />
                  </div>
                </div>
                <div
                  className="item pt-6 xs:mt-4 lg:flex
                                    xxxxxxl:my-4"
                >
                  <img
                    src={AppFeatures3}
                    className="object-contain 
                                        xs:mx-auto xs:h-20 
                                        sm:mx-auto 
                                        md:flex md:self-start
                                        xl:h-24 xl:m-0
                                        xxl:h-24 xxl:m-0
                                        xxxxxxl:h-32"
                  />
                  <div
                    className="details-container pl-12 xs:pl-0 sm:pl-0 md:pl-0 lg:pl-8
                                        xxxl:ml-10"
                  >
                    <p
                      className="text-freelyBlue-light
                                            xs:text-2xl xs:mt-4 
                                            sm:text-2xl sm:mt-2
                                            md:text-xl md:mt-0
                                            lg:text-2xl 
                                            xl:text-xl
                                            xxxxxxl:text-3xl
                                            xxxxxxxl:text-4xl fourth--title"
                    />
                    <p
                      className="text-freelyBlue-dark  pt-3
                                            xs:text-base 
                                            sm:text-lg
                                            md:text-lg md:px-16 
                                            lg:text-base lg:px-0
                                            xl:text-base
                                            xxxxxxl:text-xl
                                            xxxxxxxl:text-2xl fourth--description"
                    />
                  </div>
                </div>
              </div>
              <div className="professional-help__actions">
                {/* <button className="professional-help__button">
                  Start Online Therapy
                </button> */}
                <h1 className="professional-help__join-text">
                  Are you a therapist?{" "}
                  <span onClick={openPopup}>Join our network</span>
                </h1>
              </div>
            </div>
          </div>

          <div
            className="text-left ml-48  text-freelyBlue-dark hidden  lg:block  lg:w-1/2 lg:ml-24
                    xxxxxxxxxl:mr-64 "
          >
            <img
              className="mx-auto h-80 professional-help__phone"
              src={ScreenPhone}
            />
          </div>
        </Fade>
      </div>
    </div>
  );
}
