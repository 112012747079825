import React from "react";

import Partner2 from "./../images/new/partner2.svg";
import Partner3 from "./../images/new/partner3.svg";

export default function partners() {
  return (
    <div className="bg-freelyCream xs:py-10 md:py-24">
      <h1 className="text-center text-4xl text-freelyBlue-dark">Partners</h1>
      <section class="container md:max-w-screen-lg  mx-auto pb-10  lg:flex items-stretch py-12">
        
        <a
          class="sm:py-8 xs:py-8 mx-auto"
          href="https://www.scalehealth.com/"
          target="_black"
        >
          <img class="sm:py-8 xs:py-8 mx-auto" src={Partner2} />
        </a>
        <a
          class="sm:py-8 xs:py-8 mx-auto"
          href="https://www.spacemvmnt.com/"
          target="_blank"
        >
          <img class="sm:py-8 xs:py-8 mx-auto" src={Partner3} />
        </a>
      </section>
    </div>
  );
}
