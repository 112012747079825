import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import Carousel from "../components/carousel";
import BecomeListener from "../components/becomeListener";
import Testimonials from "../components/testimonials";
import Request from "../components/request";
import Suscribe from "../components/suscribe";
import Header from "../components/Header";
import AppFeatures from "../components/appFeatures";
import Partners from "../components/partners";
import ProfessionalHelp from "../components/ProfessionalHelp";
const IndexPage = () => (
  <Layout>
    <SEO
      title="Providing a safe environment to express yourself"
      description="Freely is creating a safe and accessible community of trusted listeners offering emotional support for people to express themselves openly and anonymously."
    />
    <div id="index" className="">
      <Header />
      <Hero />
      <Carousel />
      <ProfessionalHelp />
      <BecomeListener />
      <AppFeatures />
      <Testimonials />
      <Partners />
      <Request />
      <Suscribe />
    </div>
  </Layout>
);

export default IndexPage;
