import React from "react";
import axios from "axios";
import Fade from "react-reveal/Fade";
import "react-responsive-modal/src/styles.css";
import Download from "./../images/new/apple-download.svg";
import Quote1 from "./../images/new/quote1.svg";
import Quote2 from "./../images/new/quote2.svg";
import FirstModal from "./modal";
import Modal from "react-responsive-modal";
import PhoneFreely from "./../images/new/freely-phone.png";
import Woman from "../images/woman-small.svg";

export default class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
      value: "",
      open: false,
      data: "",
    };
  }
  handleChange = e => {
    const { target } = e;
    const { value } = target;
    this.setState(
      {
        value: value,
      },
      () => {
        console.log(this.state.value);
      }
    );
  };

  handleSubmit = event => {
    event.preventDefault();

    const data = [
      {
        Email: this.state.value,
        "Date Registered": new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/-/g, "/")
          .replace("T", " "),
      },
    ];
    axios
      .post(
        "https://v1.nocodeapi.com/ktdev/airtable/qhfoEoiTeJNCVsPB?tableName=Registrations",
        data
      )
      .then(response => {
        console.log(response);
        this.setState({
          data: response,
        });
        if (response.status === 200) {
          setTimeout(() => {
            this.onCloseModal();
          }, 4000);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };

  handleAppStore = e => {
    e.preventDefault();
    window.open(
      "https://apps.apple.com/us/app/freely-mental-health-support/id1497770633",
      "_blank"
    );
  };

  render() {
    const { open } = this.state;
    return (
      <div className=" px-12 hero">
        <div className=" ">
          <Fade delay={400}>
            <div
              className="flex flex-col hero__text-container
             md:flex-row md:pl-8 
             lg:px-12  
             xxl:pl-20 
             xxxl:pl-24 xxxl:pr-16"
            >
              <div className="md:w-7/12">
                <div className="text-center md:text-left ">
                  <h2
                    className=" text-white font-thin  leading-8                         
                        xxl:pr-16
                        xxxxxxl:text-6xl
                        xxxxxxxl:text-7xl
                        xxxxxxxxxl:text-8xl"
                  >
                    On-Demand
                    <br
                      className="hidden
                    xs:inline 
                    lg:inline"
                    />
                    Emotional Support
                  </h2>
                  <p
                    className=" py-6 text-white  leading-normal hero__ondemand-text
                          xs:text-xl xs:px-8 
                          sm:text-xl
                          md:w-11/12 
                          lg:text-2xl
                          xl:w-9/12 
                          xxl:w-11/12
                          xxxl:text-3xl
                          xxxxxl:py-8
                          xxxxxxl:text-4xl
                          xxxxxxxl:text-5xl"
                  ></p>
                  <img
                    className="md:hidden m-auto hero__woman"
                    alt="woman"
                    src={Woman}
                  />
                  <p className="hero__join-text">
                    Join our community and start talking now.
                  </p>

                  <div className="">
                    <img
                      className="cursor-pointer hero__download"
                      src={Download}
                      onClick={e => this.handleAppStore(e)}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="xs:px-8 md:w-7/12 my-6 relative xxxxxxl:my-24">
                <img className="phone mx-auto  " src={PhoneFreely} />
                <img
                  className="absolute 
                      xs:bottom-0 xs:right-0 xs:h-24
                      sm:bottom-0 sm:right-0 sm:h-36
                      md:h-24
                      lg:hidden
                "
                  src={Quote1}
                />
                <img
                  className="absolute
                      xs:inset-x-0 xs:top-0 xs:h-24
                      sm:inset-x-0 sm:top-0 sm:h-36
                      md:h-24
                      lg:hidden
                "
                  src={Quote2}
                />
                <img
                  className="hidden lg:block absolute quotes
                    lg:inset-x-0 lg:top-0 lg:h-36
                    
                  "
                  src={Quote1}
                />
                <img
                  className="hidden lg:block absolute quotes
                      lg:bottom-0 lg:right-0 lg:h-36
                "
                  src={Quote2}
                />
              </div> */}
            </div>
          </Fade>
        </div>
        <Modal
          open={open}
          onClose={this.onCloseModal}
          center
          blockScroll={false}
        >
          <FirstModal
            state={this.state}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        </Modal>
      </div>
    );
  }
}
