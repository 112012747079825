/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import AppFeatures1 from "./../images/new/app-features.svg";
import AppFeatures2 from "./../images/new/app-features-1.svg";
import AppFeatures3 from "./../images/new/app-features-2.svg";
import Incentives from "./../images/new/incentives.svg";
import PhoneTalkFreely from "./../images/new/phone-talk-freely.png";
import Fade from "react-reveal/Fade";

export default function appFeatures() {
  return (
    <div id="appFeatures">
      <div
        className=" flex py-8 md:items-center lg:items-start lg:px-12  xxl:pl-20 my-8   flex-row-reverse
                xxxxxxl:mx-10
                xxxxxxxxxl:ml-24
                xxxxxxxxxl:my-12 "
      >
        <Fade>
          <div
            className="text-left text-freelyBlue-dark
                        xs:mx-12 xs:text-center 
                        sm:mx-12 sm:text-center
                        md:px-12  
                        lg:w-1/2 lg:text-left lg:px-0 
                        xxl:ml-32
                        "
          >
            <h1
              className="text-4xl tracking-wider
                            xxxxxxl:text-5xl
                            xxxxxxxl:text-6xl"
            >
              App Features
            </h1>
            <h2
              className="text-xl pt-2 lg:text-lg
                            xxxxxxl:text-2xl
                            xxxxxxxl:text-3xl"
            >
              Freely is providing an affordable, safe and anonymous
              <br className="hidden lg:inline" />
              environment where meaningful conversations are
              <br className="hidden lg:inline" /> easily accessible.
            </h2>
            <img
              className="mx-auto professional-help__phone lg:hidden"
              src={PhoneTalkFreely}
            />
            <div className="xs:my-8 sm:my-4 lg:pt-4">
              <div className="items flex-row">
                <div
                  className="item pt-6 xs:mt-2 lg:flex
                  xxxxxxl:my-4"
                >
                  <img
                    src={AppFeatures1}
                    className="object-contain 
                    xs:mx-auto xs:h-20 
                    sm:mx-auto 
                    md:flex md:self-start
                    xl:h-24 xl:m-0
                    xxl:h-24 xxl:m-0
                    xxxxxxl:h-32"
                  />
                  <div
                    className="feature-container pl-12 xs:pl-0 sm:pl-0 md:pl-0 lg:pl-8
                                        xxxl:ml-10 "
                  >
                    <p
                      className=" text-freelyBlue-light
                      xs:text-2xl xs:mt-4 
                      sm:text-2xl sm:mt-2
                      md:text-xl md:mt-0
                      lg:text-2xl 
                      xl:text-xl
                      xxl:text-xl
                      xxxxxxl:text-3xl
                      xxxxxxxl:text-4xl first--title"
                    >
                      Free Peer-to-Peer Support
                    </p>
                    <p
                      className="text-freelyBlue-dark pt-3
                      xs:text-base 
                      sm:text-lg
                      md:text-lg md:px-16 
                      lg:text-base lg:px-0
                      xl:text-base  
                      xxxxxxl:text-xl
                      xxxxxxxl:text-2xl first--description"
                    >
                      Talk anonymously with trained listeners 24/7. Calls
                      <br className="hidden xl:inline" /> {`& `}Chats can be
                      made at any time.
                    </p>
                  </div>
                </div>
                <div
                  className="item pt-6 xs:mt-4 lg:flex
                                    xxxxxxl:my-4"
                >
                  <img
                    src={AppFeatures2}
                    className="object-contain 
                    xs:mx-auto xs:h-20 
                    sm:mx-auto 
                    md:flex md:self-start
                    xl:h-24 xl:m-0
                    xxl:h-24 xxl:m-0
                    xxxxxxl:h-32"
                  />
                  <div
                    className=" feature-container pl-12 xs:pl-0 sm:pl-0 md:pl-0 lg:pl-8
                                        xxxl:ml-10 "
                  >
                    <p
                      className=" text-freelyBlue-light
                      xs:text-2xl xs:mt-4 
                      sm:text-2xl sm:mt-2
                      md:text-xl md:mt-0
                      lg:text-2xl 
                      xl:text-xl
                      xxxxxxl:text-3xl
                      xxxxxxxl:text-4xl second--title"
                    >
                      Emergency Resource Center
                    </p>
                    <p
                      className="text-freelyBlue-dark pt-3
                      xs:text-base 
                      sm:text-lg
                      md:text-lg md:px-16 
                      lg:text-base lg:px-0
                      xl:text-base
                      xxxxxxl:text-xl
                      xxxxxxxl:text-2xl second--description"
                    >
                      Extensive resource center and Therapist-created{" "}
                      <br className="hidden xl:inline" />
                      online training to become a better listener.
                    </p>
                  </div>
                </div>
                <div
                  className="item pt-6 xs:mt-4 lg:flex
                                     xxxxxxl:my-4"
                >
                  <img
                    src={AppFeatures3}
                    className="object-contain 
                                        xs:mx-auto xs:h-20 
                                        sm:mx-auto 
                                        md:flex md:self-start
                                        xl:h-24 xl:m-0
                                        xxl:h-24 xxl:m-0
                                        xxxxxxl:h-32"
                  />
                  <div
                    className="feature-container pl-12 xs:pl-0 sm:pl-0 md:pl-0 lg:pl-8
                                        xxxl:ml-10 "
                  >
                    <p
                      className=" text-freelyBlue-light
                                            xs:text-2xl xs:mt-4 
                                            sm:text-2xl sm:mt-2
                                            md:text-xl md:mt-0
                                            lg:text-2xl 
                                            xl:text-xl
                                            xxxxxxl:text-3xl
                                            xxxxxxxl:text-4xl"
                    >
                      Affordable Online Therapy
                    </p>
                    <p
                      className="description  text-freelyBlue-dark  pt-3
                                            xs:text-base 
                                            sm:text-lg
                                            md:text-lg md:px-16 
                                            lg:text-base lg:px-0
                                            xl:text-base
                                            xxxxxxl:text-xl
                                            xxxxxxxl:text-2xl"
                    >
                      Flexible offerings from licensed professionals that{" "}
                      <br className="hidden xl:inline " />
                      you can actually afford.
                    </p>
                  </div>
                </div>
                <div
                  className="item pt-6 xs:mt-4 lg:flex
                                    xxxxxxl:my-4"
                >
                  <img
                    src={Incentives}
                    className="object-contain 
                                        xs:mx-auto xs:h-20 
                                        sm:mx-auto 
                                        md:flex md:self-start
                                        xl:h-24 xl:m-0
                                        xxl:h-24 xxl:m-0
                                        xxxxxxl:h-32"
                  />
                  <div
                    className="feature-container pl-12 xs:pl-0 sm:pl-0 md:pl-0 lg:pl-8
                                        xxxl:ml-10"
                  >
                    <p
                      className=" text-freelyBlue-light
                                            xs:text-2xl xs:mt-4 
                                            sm:text-2xl sm:mt-2
                                            md:text-xl md:mt-0
                                            lg:text-2xl 
                                            xl:text-xl
                                            xxxxxxl:text-3xl
                                            xxxxxxxl:text-4xl"
                    >
                      Incentives
                    </p>
                    <p
                      className="description text-freelyBlue-dark pt-3 
                                            xs:text-base xs:hidden
                                            sm:text-lg sm:hidden
                                            lg:block md:text-lg md:px-16 
                                            lg:text-base lg:px-0
                                            xl:text-base
                                            xxxxxxl:text-xl
                                            xxxxxxxl:text-2xl"
                    >
                      Earn by hitting certain milestones and exchange for free
                      services, discounts and more. (coming soon)
                    </p>
                    <p className="description text-freelyBlue-dark pt-3 xs:block sm:block md:block lg:hidden">
                      Accrue points by hitting certain milestones and exchange
                      points for free services, discounts and more. (coming
                      soon)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="text-left ml-48  text-freelyBlue-dark hidden  lg:block  lg:w-1/2 lg:ml-24
                    xxxxxxxxxl:mr-64 "
          >
            <img
              className="phone-talk-freely mx-auto h-80"
              src={PhoneTalkFreely}
            />
          </div>
        </Fade>
      </div>
    </div>
  );
}
