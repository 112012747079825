import axios from "axios";
import styled from "styled-components";
import ImageOne from "../images/new/request1.png";
import ImageTwo from "../images/new/request2.png";
import ImageThree from "../images/new/request3.png";
import Fade from "react-reveal/Fade";
import Modal from "react-responsive-modal";
import FirstModal from "./modal";
import React, { Component } from "react";
import Download from "./../images/new/apple-download.svg";

const ColumnOne = styled.div`
  background: url(${ImageOne}) center;
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(50px);
`;
const ColumnTwo = styled.div`
  background: url(${ImageTwo}) center;
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(50px);
`;
const ColumnThree = styled.div`
  background: url(${ImageThree}) center;
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(50px);
`;

export default class Request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
      value: "",
      open: false,
      data: "",
    };
  }
  handleChange = e => {
    const { target } = e;
    const { value } = target;
    this.setState(
      {
        value: value,
      },
      () => {
        console.log(this.state.value);
      }
    );
  };

  handleSubmit = event => {
    event.preventDefault();
    let data = {
      fields: {
        Email: this.state.value,
      },
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(process.env.AIRTABLE_URL_REGISTRATIONS, data)
      .then(response => {
        console.log(response);
        this.setState({
          data: response,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };

  handleAppStore = e => {
    e.preventDefault();
    window.open(
      "https://apps.apple.com/us/app/freely-mental-health-support/id1497770633",
      "_blank"
    );
  };
  render() {
    const { open } = this.state;
    return (
      <>
        <Fade delay="200">
          <div id="request" className="flex flex-row  relative text-center">
            <ColumnOne className=" w-1/3 h-auto"></ColumnOne>
            <ColumnTwo className="w-1/3  h-auto"></ColumnTwo>
            <ColumnThree className="  w-1/3  h-auto"></ColumnThree>
            <div className="centered absolute text-white font-extrabold">
              <h2
                className="pb-2 lg:pb-8 xs:text-2xl sm:text-2xl lg:text-5xl xl:text-6xl xl:leading-relaxed
              xxxxxxl:text-7xl"
              >
                Get the help you need.
              </h2>
              <img
                onClick={e => this.handleAppStore(e)}
                className="mx-auto mt-8 cursor-pointer"
                src={Download}
                alt=""
              />
            </div>
          </div>
        </Fade>
        <Modal
          open={open}
          onClose={this.onCloseModal}
          center
          blockScroll={false}
        >
          <FirstModal
            state={this.state}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        </Modal>
      </>
    );
  }
}
