import React from "react";
import { Carousel } from "react-responsive-carousel";
import Fade from "react-reveal/Fade";

export default function testimonials() {
  const [intervalz, setIntervalz] = React.useState(1600); //initial state here represents the first image.

  const data = {
    title: "Verified freely members",
    itemOne: {
      content:
        "“Great experience on Freely. My listener did just that - listen. Rarely do we feel truly heard in today's society.”",
    },
    itemTwo: {
      content:
        "“I love being a listener on Freely. The training course was super informative and it actually feels like I'm helping people!”",
    },
    itemThree: {
      content:
        "“Freely makes me feel like I'm not so alone anymore. I always have someone to talk to.”",
    },
  };
  const onChange = (index, item) => {
    setIntervalz(item.props["data-interval"]);
  };
  return (
    <>
      <div id="testimonials" className="pt-8 pb-12">
        <Fade delay={100}>
          <div className="container mx-auto px-6 text-center text-freelyBlue-dark">
            <div className="pt-4 text-left  ">
              <h3 className="font-bold text-2xl
              xxxxxxl:text-3xl
              xxxxxxxl:text-4xl" >{data.title}</h3>
            </div>
            <Carousel
              onChange={onChange}
              autoPlay
              interval={intervalz}
              infiniteLoop={true}
              showThumbs={false}
            >
              <div data-interval={1600} className="py-4 mb-8  text-left  ">
                <p className="pt-4 pb-10 ">{data.itemOne.content}</p>
              </div>
              <div data-interval={1600} className="py-4  mb-8  text-left">
                <p className="pt-4 pb-10">{data.itemTwo.content}</p>
              </div>
              <div data-interval={1600} className="py-4 mb-8  text-left">
                <p className="pt-4 pb-10 text-lg">{data.itemThree.content}</p>
              </div>
            </Carousel>
          </div>
        </Fade>
      </div>
    </>
  );
}
