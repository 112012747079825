import React from "react";
import Logo from "./../images/new/Freely-Logo.svg";
import Fade from "react-reveal/Fade";

export default function Modal(props) {
  return (
    <div className=" bg-freelyBlue-dark text-white flex flex-col justify-center items-center py-10 md:px-32 lg:px-32">
      <img src={Logo} className="w-1/3 " />
      <hr className="w-1/2 lg:w-1/2 mt-5" />
      <div className=" py-6 text-center font-bold lg:px-10">
        {!props.state.data && (
          <>
            <h4 className="text-base  lg:text-2xl">
              We appreciate your interest in Freely!
            </h4>
            <p className="text-xs pt-3 pb-6 lg:text-base">
              You caught us before we are ready. Enter your email below, and
              you’ll be one of the first to know when we launch. We can’t wait
              to join you on your journey.
            </p>
            <form
              className=" flex form-modal justify-center items-center flex-row  lg:py-6"
              onSubmit={ props.handleSubmit}
            >
              <input
                name="email"
                type="email"
                className=" input-modal text-xs w-9/12 mt-2 text-white bg-freelyBlue-dark px-3 py-4 lg:py-5 lg:mt-0"
                value={props.state.value}
                onChange={e => {
                  props.handleChange(e);
                }}
                placeholder="Enter your email to stay up to date...."
              />
              <button
                type="submit"
                className="input-modal text-xs  w-3/12 mx-auto bg-freelyBlue-light font-bold py-4  lg:py-5 mt-2 uppercase lg:mt-0"
              >
                Submit{" "}
              </button>
            </form>
          </>
        )}
        {props.state.data && (
          <Fade delay={600}>
            <p className="py-16  text-white bg-freelyBlue-dark  lg:text-2xl lg:mt-0 lg:py-20">
              Thank you! We will be in touch with you soon.
            </p>
          </Fade>
        )}
      </div>
    </div>
  );
}
