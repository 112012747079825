/* eslint-disable prettier/prettier */
import React from "react";
import Modal from "react-responsive-modal";
import axios from "axios";
import Logo from "../images/new/Freely-Logo.svg";
import Download from "../images/new/download.svg";
import FirstModal from "./modal";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
      value: "",
      open: false,
      data: "",
    };
  }

  handleChange = e => {
    const { target } = e;
    const { value } = target;
    this.setState(
      {
        value,
      },
      () => {
        console.log(this.state.value);
      }
    );
  };

  handleSubmit = event => {
    event.preventDefault();

    const data = [
      {
        Email: this.state.value,
        "Date Registered": new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/-/g, "/")
          .replace("T", " "),
      },
    ];
    axios
      .post(
        "https://v1.nocodeapi.com/ktdev/airtable/qhfoEoiTeJNCVsPB?tableName=Registrations",
        data
      )
      .then(response => {
        console.log(response);
        this.setState({
          data: response,
        });
        if (response.status === 200) {
          setTimeout(() => {
            this.onCloseModal();
          }, 4000);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.setState({ data: false });
  };

  handleAppStore = e => {
    e.preventDefault();
    window.open(
      "https://apps.apple.com/us/app/freely-mental-health-support/id1497770633",
      "_blank"
    );
  };

  render() {
    const { open } = this.state;
    return (
      <div className="header sm:py-2 md:py-4 xxxl:pl-8 flex ">
        <div
          className="w-1/2 flex justify-between items-center md:ml-16 "
          style={{
            gridTemplateColumns:
              "repeat(auto-fill, minmax(min(169px, 100%), 1fr))",
          }}
        >
          <img
            className="sm:h-8 sm:ml-1 xxxxxxxl:h-24 md:object-contain object-left md:h-16 logo"
            src={Logo}
            alt="Logo"
          />
          <a
            className="header__title cursor-pointer  hidden md:block"
            href="#therapy"
          >
            Therapy
          </a>
          <a
            className="header__title cursor-pointer  hidden md:block"
            href="#listener"
          >
            Become a Listener
          </a>
        </div>
        <div className="w-1/2 flex justify-center items-center hidden md:flex justify-center align-center">
          <img
            className="xs:h-8 sm:h-8 xxxxxxxl:h-24  md:object-contain md:object-right   md:h-16 cursor-pointer header__download"
            onClick={e => this.handleAppStore(e)}
            src={Download}
            alt={"Download"}
          />
        </div>

        {/* <Modal
          open={open}
          onClose={this.onCloseModal}
          center
          blockScroll={false}
        >
          <FirstModal
            state={this.state}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        </Modal> */}
      </div>
    );
  }
}
