import React from "react";
import drop from "../images/new/Vector.png";
import Fade from "react-reveal/Fade";

export default function becomeListener() {
  return (
    <>
      <div id="listener" className="lg:flex lg:flex-row">
        <div
          className="  py-12 px-6 lg:pr-0 lg:pl-6 
        lg:w-1/2 lg:flex lg:flex-col "
        >
          <div
            className="first-section  lg:self-end
          xl:self-center"
          >
            <div
              className=" box-first-section  flex  flex-col justify-center  lg:self-end
          "
            >
              <Fade delay={400}>
                <h2
                  className="text-freelyCream text-4xl text-center mb-4          lg:text-left
                  xxxxxxl:text-5xl
                  xxxxxxxl:text-6xl"
                >
                  Become A Listener
                </h2>
                <h3
                  className="text-lg text-white text-center lg:text-left xl:text-2xl
                xxxxxxxl:text-3xl"
                >
                  {" "}
                  Complete our free course to improve your active{" "}
                  <br className="hidden lg:inline" />
                  listening skills and become a Freely Listener.
                </h3>

                <ul className="px-2">
                  <li className="py-8">
                    <img src={drop} />
                    <p
                      className="
                    xxxxxxl:text-xl"
                    >
                      {" "}
                      Learn skills that you can use to help friends, family and
                      those around you.
                    </p>
                  </li>
                  <li>
                    <img src={drop} />
                    <p
                      className="
                    xxxxxxl:text-xl"
                    >
                      {" "}
                      Set your own availability and take calls from anywhere.
                    </p>
                  </li>
                  <li className="py-8">
                    {" "}
                    <img src={drop} />
                    <p className="xxxxxxl:text-xl">
                      Join a community of like-minded individuals that believe
                      in helping others.
                    </p>
                  </li>
                  <div className="flex flex-col justify-center">
                    <a
                      href="https://my.talkfreely.io/"
                      target="_blank"
                      className=" button_transform mx-auto bg-freelyBlue-light text-white px-10 py-5 text-lg lg:ml-0 lg:mr-auto "
                    >
                      Get Started{" "}
                    </a>
                  </div>
                </ul>
              </Fade>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}
